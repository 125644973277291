export const english = {
  back: 'Back',
  case_no: 'Case #',
  city_block: 'a city block (110 yards)',
  couple_blocks: 'a couple city blocks (1/8 mile)',
  quarter_mile: 'a quarter  mile',
  half_mile: 'a half mile',
  mile: 'a mile',
  chart_view: 'Chart',
  click_to_crime: 'Click to crime in map',
  crime: 'Crime',
  crimes: 'Crimes',
  crimes_by_address_filename: 'crimes_by_address.csv',
  crimes_by_street_filename: 'crimes_by_street.csv',
  crimes_by_neighborhood_filename: 'crimes_by_neighborhood',
  crime_pie_chart: 'Crime pie chart',
  date: 'Date',
  during: 'during',
  last_30_days: 'the last 30 days',
  last_6_months: 'the last 6 months',
  last_year: 'the last year',
  last_5_years: 'the last 5 years',
  all_time: 'all time',
  law_beat: 'Law Beat',
  list_view: 'List view',
  location: 'Location',
  map_view: 'Map view',
  no_results_found: 'No results found',
  placeholder: 'Search...',
  type: 'Type',
  view: 'view',
  view_apd_reports: 'View APD reports',
  within: 'within',
};