export const english = {
  adopted_annual_budget: 'Adopted Annual Budget',
  back: 'Back',
  bond_definition_note: 'In this dashboard, bond projects refer to those projects funded by the General Obligation (GO) bond funds. The GO bond amounts were approved by Asheville voters.',
  bond_project: 'Bond project',
  bond_project_statuses: 'bond project statuses',
  budget: 'Budget',
  capital_projects: 'Capital Projects',
  collapsible_text: 'Click here to learn how projects in each category are supported',
  contact_email: 'Contact email',
  contact_phone:  'Contact phone',
  data_info: 'Some explanation of the data and limitations of the data',
  estimated_construction_timeframe: 'Estimated construction timeframe',
  funding_info: 'projects within the City’s General Capital Improvement Program (CIP) are funded with a combination of general tax revenue, municipal debt and external grants or partnerships.',
  housing_bond_info: 'Housing Bond projects for housing affordability provide additional support for the Housing Trust Fund and other programs that assist in creating diverse and affordable housing choices. The projects also include support in the development of affordable housing on key City-owned sites.',
  include_only_bond_projects: 'Include only bond projects',
  ongoing_programs: 'Ongoing programs and regular maintenance projects may not be represented in this dashboard. For a complete list including ongoing and maintenance projects within the City\'s General CIP, please view the adopted',
  other_category_note: 'Projects categorized as "Other" support facility upgrades and economic development initiatives.',
  parks_bond_info: 'Parks Bond projects are additional projects that support the completion of major improvements to five parks and recreation facilities; acquiring land for parks; and improving outdoor courts, playgrounds and ball field lighting throughout the city.',
  phase: 'Phase',
  project: 'Project',
  project_budgets_note: 'Please note: Current project budgets include prior year funding and may change throughout the life of the project.',
  project_contact: 'Project contact',
  project_website: 'Project Website',
  understand_the_capital_projects_data: 'Understand the Capital Projects data',
  search_by_note: 'You can search geographically AND by address:',
  search: 'Search...',
  select_a_category: 'Select a category above to show project data',
  spent: 'Spent',
  table_of: 'Table of',
  total_budget: 'Total budget',
  total_budget_note: 'Issued general obligation (GO) bond amount is $74 million. Actual total dollar amount will increase due to alternative funding sources.',
  transportation_bond_info: 'Transportation Bond projects are additional projects that support the completion of road resurfacing and sidewalk improvements; new sidewalk and greenway projects; and pedestrian safety projects such as bus shelters, accessible crossings, signals, and traffic calming.',
  try_project_map: 'Try our project map',
  under_contract: 'Under contract',
  view_cip_plan: 'View the City\'s General Capital Improvement Plan (CIP)',
  zip_code: 'Zip code',
};
