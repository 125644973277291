export const spanish = {
  adopted_annual_budget: 'Presupuesto Anual Adoptado',
  back: 'Volver',
  bond_definition_note: 'En este tablero, proyectos de bono son los proyectos fundados por la fianza de obligaci\xF3n general. La fianza de obligaci\xF3n general fue aprobada por el electorado de Asheville.',
  bond_project: 'Proyecto de bono',
  bond_project_statuses: 'estados de proyectos de bono',
  budget: 'Presupuesto',
  capital_projects: 'Proyectos Capitales',
  collapsible_text: 'Haga clic aqu\xED para aprender como los proyectos en cata categor\xEDa son apoyados',
  contact_email: 'Email de contacto',
  contact_phone: 'Tel\xE9fono de contacto',
  data_info: 'Alguna explanaci\xF3n de los datos y las limitaciones de los datos.',
  estimated_construction_timeframe: 'Estimado marco de tiempo de construcci\xF3n',
  funding_info: 'proyectos en el plan general de mejora de capital de la ciudad (CIP) son financiados por una combinaci\xF3n de ingresos fiscales generales, deuda municipal, subvenciones externas y sociedades.',
  housing_bond_info: 'Proyectos del bono de vivienda asequible proveen apoyo adicional al fondo de vivienda y otros programas que asisten a crear opciones diversas y econ\xF3micas de vivienda. Los proyectos incluyen apoyo en el desarollo de vivienda asequible en propiedades claves de la ciudad.',
  include_only_bond_projects: 'Incluye solamente proyectos de bono',
  ongoing_programs: 'Es posible que programas en curso y proyectos de mantenimiento regular no aparezcan en este tablero de control. Para una lista completa de proyectos en curso y de mantenimiento regular en el plan general de mejora de capital de la ciudad, lee el',
  other_category_note: 'Proyectos categorizados como "Otro" apoyan a la modernizaci\xF3n de instalaciones e iniciativas de desarrollo econ\xF3mico.',
  parks_bond_info: 'Proyectos del bono de parques son proyectos adicionales que apoyan a la finalizaci\xF3n de mejoras importantes de cinco parques e instalaciones de recreaci\xF3n; adquiriendo terrenos para parques; mejorando canchas exteriores, zonas de juegos e iluminaci\xF3n de campos de pelota en toda la ciudad.',
  phase: 'Fase',
  project: 'Proyecto',
  project_budgets_note: 'Tenga en cuenta: los presupuestos actuales de proyectos incluyen el financiamiento del a\xF1o anterior y puedan cambiar a lo largo de la vida de cada proyecto.',
  project_contact: 'Contacto del proyecto',
  project_website: 'Sitio web del proyecto',
  understand_the_capital_projects_data: 'Entender los datos de los proyectos capitales',
  search: 'Buscar...',
  search_by_note: 'Se puede hacer b\xFAsquedas por geograf\xEDa o por direcci\xF3n',
  select_a_category: 'Seleccione una categor\xEDa arriba para mostrar datos sobre proyectos',
  spent: 'Gastado',
  table_of: 'Tabla de',
  total_budget: 'Presupuesto total',
  total_budget_note: 'La cantidad de los bonos de obligaci\xF3n general emitidos es $74 millones. El total actual de d\xF3lares incrementar\xE1 debido a fuentes alternativas de financiaci\xF3n.',
  transportation_bond_info: 'Proyectos del bono del transporte son proyectos adicionals que contribuyen a la finalizaci\xF3n de la repavimentaci\xF3n de carreteras y mejoras de aceras; nuevas proyectos de aceras y caminos verdes; y proyectos de seguridad peatonal como cobertizos de paradas de autob\xFAs, accesibles cruces peatonales, se\xF1ales, y medidas para calmar el tr\xE1fico.',
  try_project_map: 'Probar nuestro mapa de proyectos',
  under_contract: 'Bajo contrato',
  view_cip_plan: 'Ver el plan de mejora de capital de la ciudad (CIP)',
  zip_code: 'C\xF3digo de zip',
};
