export const spanish = {
  address: 'Dirrecci\xF3n',
  addresses_by_street_filename: 'dirrecciones_en_la_calle.csv',
  addresses_by_neighborhood_filename: 'dirrecciones_en_el_barrio.csv',
  address_and_owner_mailing_lists: 'Listas de correo de direcciones y propietarios',
  about_this_address: 'Sobre esta dirrecci\xF3n',
  back_to_neighborhood: 'Volver al barrio',
  back_to_place_matches: 'Volver a lugares',
  back_to_street: 'Volver a la calle',
  back_to_search: 'Volver a buscar',
  brush_collection: 'Colecci\xF3n de la broza',
  brush_week: 'Programa de broza',
  data_type: 'Dirrecci\xF3n',
  every: 'Cada',
  historic_district: 'Distrito hist\xF3rico',
  list_view: 'Lista',
  local_landmark: 'Punto de referencia local',
  map_view: 'Mapa',
  neighborhood: 'Barrio',
  no_neighborhood_name: 'Barrio sin nombre',
  climate: 'Censo Block Group',
  no_climate: 'Censo Block Group sin nombre',
  no_city_pickup: 'no proporcionada por la ciudad de Asheville',
  no_information_available: 'No hay informaci\xF3n disponisble',
  no_results_found: 'No se ha encontrado resultados',
  of_next_week: 'de la pr\xF3xima semana',
  of_this_week: 'de esta semana',
  owner: 'Propietario',
  placeholder: 'Buscar...',
  place_on_curb_by_7am_monday: 'Coloque en el bordillo de la acera antes de las 7 de la ma\xF1ana del lunes',
  property_information: 'Detalles de la propiedad',
  recycle_week: 'Programa de reciclaje',
  recycling_collection: 'Colecci\xF3n de Reciclaje',
  report_with_the_asheville_app: 'Reportar con el Asheville App',
  sometime_this_week: 'Durante esta semana',
  sometime_next_week: 'Durante la pr\xF3xima semana',
  street_maintenance: 'Mantenimiento de la calle',
  street: 'Calle',
  trash_collection: 'Recolecci\xF3n de basura',
  weekdays: {
    MONDAY: 'LUNES',
    TUESDAY: 'MARTES',
    WEDNESDAY: 'MI\xC9RCOLES',
    THURSDAY: 'JUEVES',
    FRIDAY: 'VIERNES',
    SATURDAY: 'S\xC1BADO',
    SUNDAY: 'DOMINGO',
  },
  zoning: 'Zonificaci\xF3n',
};
