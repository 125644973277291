export const english = {
  address: 'Address',
  addresses_by_street_filename: 'addresses_by_street.csv',
  addresses_by_neighborhood_filename: 'addresses_by_neighborhood.csv',
  address_and_owner_mailing_lists: 'Address & Owner Mailing Lists',
  about_this_address: 'About this address',
  back_to_neighborhood: 'Back to neighborhood',
  back_to_place_matches: 'Back to place matches',
  back_to_street: 'Back to street',
  back_to_search: 'Back to search',
  brush_collection: 'Brush Collection',
  brush_week: 'Brush Week',
  data_type: 'Address',
  every: 'Every',
  historic_district: 'Historic district',
  list_view: 'List view',
  local_landmark: 'Local landmark',
  map_view: 'Map view',
  neighborhood: 'Neighborhood',
  no_neighborhood_name: 'No neighborhood name',
  climate: ' Climate Threats and Vulnerability',
  no_climate: 'Unknown Census Block Group',
  no_city_pickup: 'No city pickup',
  no_information_available: 'No information available',
  no_results_found: 'No results found',
  of_next_week: 'of next week',
  of_this_week: 'of this week',
  owner: 'Owner',
  placeholder: 'Search...',
  place_on_curb_by_7am_monday: 'Place on curb by 7am Monday',
  property_information: 'Property information',
  recycle_week: 'Recycle Week',
  recycling_collection: 'Recycling collection',
  report_with_the_asheville_app: 'Report with the Asheville App',
  sometime_this_week: 'Sometime this week',
  sometime_next_week: 'Sometime next week',
  street: 'Street',
  street_maintenance: 'Street Maintenance',
  trash_collection: 'Trash Collection',
  weekdays: {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY',
  },
  zoning: 'Zoning',
};
